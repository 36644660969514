import React from 'react'
import {motion} from "framer-motion"
import {pageAnimation,titleAnim,fade} from "../animation"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faEnvelope,faPhone,faHome} from "@fortawesome/free-solid-svg-icons"
import Wave from "../compoments/Wave"

const ContactUs=()=>{

    return(
        <motion.div className="contactPage" variants={pageAnimation} initial="hidden" animate="show" exit="exit">
<Wave/>
            <div className="contactContainer">
            <div className="contactInfo">   

        <div className="hidden"><motion.h1 variants={titleAnim} >KONTAKT</motion.h1></div>
        <motion.div className="contactBox" variants={fade}><FontAwesomeIcon size="2x" className="icon" icon={faEnvelope}></FontAwesomeIcon><h3>piotradamczyk5@o2.pl</h3></motion.div>
        <motion.div className="contactBox" variants={fade}><FontAwesomeIcon size="2x" className="icon" icon={faPhone}></FontAwesomeIcon><h3><b>667 831 680</b> lub <b>884 678 976</b></h3></motion.div>
        <motion.div className="contactBox" variants={fade}><FontAwesomeIcon size="2x" className="icon" icon={faHome}></FontAwesomeIcon><h3>Tylmanowa os. Brzegi 132</h3></motion.div></div>
    
    <div className="contactFormContainer">
<div className="hidden"><motion.h1  variants={titleAnim}>TU JESTEŚMY!</motion.h1></div>
<motion.div variants={fade} className="mapContainer"><iframe title="unique" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5184.291838247412!2d20.416193618108398!3d49.48175057785376!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x473e00e0e54c4e89%3A0x65717a7aa0dbeca5!2sBrzegi%20132%2C%2034-451%20Tylmanowa!5e0!3m2!1spl!2spl!4v1608726905312!5m2!1spl!2spl" frameborder="0"  allowfullscreen="" aria-hidden="false" tabindex="0"></iframe></motion.div>
</div>
     </div>


     

        </motion.div>
    )
}
export default ContactUs;