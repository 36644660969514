import React from "react"
import {useInView} from "react-intersection-observer"
import {useAnimation,motion} from "framer-motion"
import {footerAnim} from "../animation"
const Footer = ()=>{
const [element,view] = useInView({threshold:0.4});
const controls = useAnimation();
if(view){
controls.start('show');
}else{
controls.start('hidden');
}
return(
<motion.footer variants={footerAnim} animate={controls} initial="hidden" ref={element}>
    <div className="footerContent">
<p>Copyright 2020 © Barier
</p>
</div>

</motion.footer>
)


}

export default Footer

