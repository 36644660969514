
import React from "react"

import Toggle from "../compoments/Toggle"
import {AnimateSharedLayout} from "framer-motion"

const FaqSection = () =>{

return(
<div className="faqSection">
    
<h2>Jakieś pytania?</h2>
<AnimateSharedLayout>
<Toggle title="Jaki jest nabliższy wolny termin?">
    <div className="answer"><p>Zazwyczaj pracę mamy zaplanowaną od 4 do 6 miesięcy do przodu. Zawsze warto jednak zadzwonić i zapytać się o termin ;)</p> </div>
</Toggle>

<Toggle title="Jakie są ceny?">
   
    <div className="answer"><p>Każda praca jest inna. Cenny dostosowujemy indywidualnie do potrzeb i oczekiwań klienta.</p> </div>
    
</Toggle>

<Toggle title="Jak przygotowujecie materiał do malowania?">
  
    <div className="answer"><p>Dokładamy wszelkich starań aby farba jak najlepiej zespoiła się z ogrodzeniem. Całość zawsze matujemy i usuwamy niedoskonałości powstałe podczas cynkowania. Następnie całość poddajemy "trawieniu" przy pomocy specjalnego środka. Więcej na ten temat znajduje się w sekcji poniżej.</p> </div>
    
</Toggle>

<Toggle title="Dlaczego zdecydowaliśmy się oddawać ogrodzenia do proszkowego malowania?">
   
    <div className="answer"><p>Jakiś czas temu postanowiliśmy oddać malowanie specjalistom w temacie malowania proszkowego. W zamian za nieznaczny wzrost ceny mogą Państwo liczyć na twardszą ale i lepiej wyglądającą powłokę lakierniczą.
    Dzięki temu można również wybrać między farbą gładką a "strukturą".</p> </div>
    
</Toggle>

<Toggle title="Czy montujecie również automatykę do bram?">
    <div className="question">
    <div className="answer"><p>Sami nie zajmujemy się automatyzacją bram, jednak od lat kierujemy swoich klientów do sprawdzonego fachowca.</p> </div>
    </div>
</Toggle>
</AnimateSharedLayout>
</div>

)

}

export default FaqSection;
