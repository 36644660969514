export const pageAnimation = {

    hidden:{
        opacity:0,
        y:300
    },
    show:{
        opacity:1,
        y:0,
        transition:{
            duration:0.5,
            when:"beforeChildren",
            staggerChildren:0.25,
        }
    },
    exit:{
        opacity:0,
     
        transition:{
            duration:0.75,
            
        }
    }
}

export const titleAnim={
    hidden:{y:200},
    show:{
        y:0,
        transition:{
            duration:0.75,
            ease:"easeOut",
     }}
}

export const footerAnim={
    hidden:{x:-200,opacity:0},
    show:{
        x:0,
        opacity:1,
        transition:{
            duration:0.25,
            ease:"easeOut",
        }
    }
}

export const fade = {
    hidden:{opacity:0},
    show:{
        opacity:1,
        transition:{ease:'easeOut',duration:0.5},
    }
} 

export const photoAnim={
    hidden:{
        scale:1.5,
        opacity:0,
    },
    show:{scale:1, opacity:1, 
        transiton:{
        ease:"easeInOut",
        duration:2,
    }
    }
}