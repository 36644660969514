import React from "react"
import AboutSection from "../compoments/AboutSection"
import FaqSection from "../compoments/FaqSection"
import Work from "../compoments/Work"
import {motion} from "framer-motion"
import {pageAnimation} from "../animation"

const AboutUs  = () => {

return(
    <div>
<motion.div variants={pageAnimation} initial="hidden" animate="show" exit="exit">
<AboutSection/>
<FaqSection/>
</motion.div>
    </div>
)
}

export default AboutUs