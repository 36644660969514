import React from "react"
import {motion} from "framer-motion";

import {Link,useLocation} from 'react-router-dom'
const Nav = () =>{
const {pathname} = useLocation();

return(
    <nav>
       <h3><Link to="/">Barier</Link></h3>
<div className="menu">

<div style={{position:"relative"}}>
        <h1><Link  to="/">O nas</Link></h1>
        <motion.div transition={{duration:0.75}} initial={{width:"0%"}} animate={{width: pathname === '/'?'70%':'0%'}} className="navLine"></motion.div>
        </div>
        <div style={{position:"relative"}}>
        <h1><Link to="/galeria">Galeria</Link></h1>
        <motion.div transition={{duration:0.75}} initial={{width:"0%"}} animate={{width: pathname === "/galeria"?'70%':'0%'}} className="navLine"></motion.div>
        </div>
        <div style={{position:"relative"}}>
        <h1><Link to="/kontakt">Kontakt</Link></h1>
        <motion.div transition={{duration:0.75}} initial={{width:"0%"}} animate={{width: pathname === '/kontakt'?'70%':'0%'}} className="navLine"></motion.div>
        </div>
        </div>
    </nav>
)

}

export default Nav;