import React from 'react';
import useFirestore from "../useFirestore";
const ImageGrid = ({setSelectedImg,type}) =>{

    
const {docs} = useFirestore("images");
    return(
        <div>
        <div className="imageGrid">

            {docs && docs.map(doc =>{
                if(doc.typ==type){
                return(
                        <div key={doc.id} className="imageWrap" onClick={()=>setSelectedImg(doc.url)}> <img src={doc.url} alt="uploaded"/></div>
                )
            }
                
        

})}
        </div>
        </div>
    )
}
export default ImageGrid;