import React from 'react'
import "./styles/main.scss"

import {Switch,Route, useLocation} from 'react-router-dom'

import AboutUs from "./pages/AboutUs.js"
import ContactUs from "./pages/ContactUs"
import Gallery from "./pages/Gallery"

import Nav from "./compoments/Nav"
import Footer from "./compoments/Footer"

import {AnimatePresence} from "framer-motion"


function App() {
  const location=useLocation();
  return (
    <div className="App">
      <Nav/>
      <AnimatePresence exitBeforeEnter>
      <Switch location={location} key={location.pathname}>
      <Route exact path="/"><AboutUs/></Route>
      <Route path="/kontakt"><ContactUs/></Route>
      <Route path="/galeria"><Gallery/></Route>
      </Switch>
      </AnimatePresence>
      <Footer/>
    </div>
  );
}

export default App;
