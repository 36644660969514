import firebase from "firebase/app";

import 'firebase/firestore';

  var firebaseConfig = {
    apiKey: `${process.env.FIREBASE_API_KEY}`,
    authDomain: "barier-6a4e0.firebaseapp.com",
    projectId: "barier-6a4e0",
    storageBucket: "barier-6a4e0.appspot.com",
    messagingSenderId: "52972206174",
    appId: `${process.env.FIREBASE_API_ID}`
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);


  const barierFirestore = firebase.firestore();

  export {barierFirestore};