import { useState, useEffect } from 'react'
import { barierFirestore} from './firebase/config';

const useFirestore = (collection)=>{
const [docs, setDocs] = useState([]);
 
 useEffect(()=>{
     const unsub= () => barierFirestore.collection(collection)
barierFirestore.collection(collection)
.orderBy("typ","asc")
.onSnapshot((snap)=>{
    let documents = [];
    snap.forEach(doc =>{
        documents.push({...doc.data(),id:doc.id})
    });
    setDocs(documents);
});

return ()=> unsub();
 },[collection])

 return {docs}
}

export default useFirestore;