import React from "react"
import {Link} from 'react-router-dom'
import aboutImg from "../images/maintwo.jpg";
import Wave from "./Wave"

import {motion} from "framer-motion"
import {titleAnim,fade,photoAnim} from '../animation'

const AboutSection = () =>{


return(
    <div className="aboutSection">
        <Wave/>
<div className="description">

    <div  className="title">
  
    <div className="hide"><motion.h2 variants={titleAnim} >Pracujemy na</motion.h2></div>
    <div className="hide"><motion.h2 variants={titleAnim}><span>Twoje</span> bezpieczeństwo</motion.h2></div>
    <div className="hide"><motion.h1  variants={titleAnim}>BARIER</motion.h1></div>
    <motion.p variants={fade}>Od ponad 10 lat tworzymy bramy, ogrodzenia, balustrady, a także od czasu do czasu pospawiemy jakiś stołek.</motion.p>
<motion.button variants={fade}><Link to="/kontakt"> Kontakt</Link></motion.button>
</div>

</div>
<div className="image"><motion.img variants={photoAnim} src={aboutImg} alt="Furtka"/></div>

</div>



)

}

export default AboutSection;
