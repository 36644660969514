import React, { useState } from 'react'
import {motion} from "framer-motion"
import {pageAnimation} from "../animation"
import ImageGrid from "../compoments/ImageGrid"
import Modal from "../compoments/Modal"
import {useInView} from "react-intersection-observer"
const Gallery=()=>{

   const [ogrodzenia,setOgrodzenia]  = useState(false);
   const [balustrady,setBalustrady]  = useState(false);
   const [inne,setInne]  = useState(false);

    const [selectedImg,setSelectedImg] = useState(null);
    return(
        <motion.div variants={pageAnimation} initial="hidden" className="galleryPage" animate="show" exit="exit">
        <h1>Galeria</h1>

        <h1>Bramy i furtki</h1>
       
       <ImageGrid setSelectedImg={setSelectedImg} type={1}/>

        <h1>Ogrodzenia</h1>
        {ogrodzenia===false && <button  onClick={()=>{setOgrodzenia(true)}}>Załaduj zdjęcia</button>}
        {ogrodzenia===true && <ImageGrid setSelectedImg={setSelectedImg}  type={2}/>}

        <h1>Barierki</h1>
        {balustrady===false && <button  onClick={()=>{setBalustrady(true)}}>Załaduj zdjęcia</button>}
        {balustrady===true && <ImageGrid  setSelectedImg={setSelectedImg} type={3}/>}

        <h1>Pozostałe</h1>
        {inne===false && <button  onClick={()=>{setInne(true)}}>Załaduj zdjęcia</button>}
        {inne===true && <ImageGrid setSelectedImg={setSelectedImg} type={4}/>}

        {selectedImg && <Modal selectedImg={selectedImg} setSelectedImg={setSelectedImg}/>}
        </motion.div>
    )
}
export default Gallery;