import React from "react"

const Modal =({selectedImg,setSelectedImg})=>{

    const handleClick =(e)=>{
        setSelectedImg(null);
    }

return(
<div className="bigImage" onClick={handleClick}>
    <img src={selectedImg} alt=""/>
</div>
)
}

export default Modal;