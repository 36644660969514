import React from "react"
import {motion} from 'framer-motion'

const Wave = () =>{
    return(

<svg className="waveAbout"  viewBox="0 0 1920 388" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d)">
<motion.path initial={{pathLength:0,pathOffset:1}} animate={{pathLength:1,pathOffset:0}} transition={{duration:2}} d="M2181.5 84.5C2435.87 287.381 2253 100.5 1672 84.5C1407.96 77.2287 959 371.5 620 84.5C281 -202.5 -361.5 408 -245 370.5" stroke="#F27405BB" strokeWidth="10"/>
</g>
<defs>
<filter id="filter0_d" x="-269.934" y="0.507477" width="2574.4" height="386.66" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="2"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
</filter>
</defs>
</svg>
    )
}

export default Wave;